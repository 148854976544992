import { useEffect } from 'react';
import useContactInfo from '../hooks/useContactInfo';

function EmailPage() {
  const { websiteEmail } = useContactInfo();

  useEffect(() => {
    window.location.replace(websiteEmail);
  }, [websiteEmail]);

  return null;
}

export default EmailPage;
