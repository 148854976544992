import { graphql, useStaticQuery } from 'gatsby';

function useContactInfo() {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SOCIAL_MEDIA_QUERY {
        markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
          frontmatter {
            address
            businessHours
            comercialEmail
            facebook
            instagram
            personalEmail
            phones
            websiteEmail
            whatsapp
            whatsappMsg
          }
        }
      }
    `
  );

  return markdownRemark.frontmatter;
}

export default useContactInfo;
